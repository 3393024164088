import $ from "jquery";
import env from '../../../env.json';

let url = env.APP_PATH + "auth/signup";
const param = location.search;

if(param) {
  url = url + "?" + param.slice(1);
}

$(".js-cta").attr("href", url);
