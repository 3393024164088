import $ from "jquery";

$('.js-price-tab').on('click', (event) => {
  $('.js-price-tab').removeClass('-active');
  $(event.currentTarget).addClass('-active');
  let index = $('.js-price-tab').index(event.currentTarget);
  $('.js-price-content').removeClass('-active');
  $('.js-price-content').eq(index).addClass('-active');
   return false;
});
