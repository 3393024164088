import $ from "jquery";

const fixedCta = $('#js-fixedCta');

$(window).scroll(() => {
  if ($(event.currentTarget).scrollTop() > 800) {
    fixedCta.addClass('-active');
  } else {
    fixedCta.removeClass('-active');
  }
});
