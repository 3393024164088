import $ from "jquery";

$('#js-hamburger').on('click', (event) => {
  $(event.currentTarget).toggleClass('-active');
  $('#js-nav').slideToggle();
  $('html').toggleClass('-disable');
  return false;
});

$('#js-nav a').on('click', (event) => {
  $('#js-nav').slideUp();
  $('#js-hamburger').removeClass('-active');
  $('html').removeClass('-disable');
});
